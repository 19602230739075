<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router/auto";
import { setPageTitle } from "../../../utils/pageTitle";
import OrderHeader from "../../../components/order/OrderHeader.vue";
import useOutboundOrders from "@/hooks/useOutboundOrders";
import OutboundOrderLayout from "@/layouts/OutboundOrderLayout.vue";
import useCarriers from "@/hooks/useCarriers";

const { outboundLoading, outboundOrder, fetchOutboundOrder, fetchOutboundOrderShipments} = useOutboundOrders();
const { carriers, fetchCarriers } = useCarriers();
const route = useRoute();
const addShipmentOpen = ref(false);
const addPackageOpen = ref(false);
const shipments = ref(null);

onMounted(async () => {
  await fetchOutboundOrder(route.params.id).then(() => {
    setPageTitle(`Outbound Order #OUT-${outboundOrder.value.id}`);
  });
  await fetchCarriers();
  shipments.value = await fetchOutboundOrderShipments(route.params.id);
});

const showAddPackageFor = (shipmentId) => {
  if(addPackageOpen.value == shipmentId) {
    addPackageOpen.value = false;
  } else {
    addPackageOpen.value = shipmentId;
  }
}

</script>

<template>
  <OutboundOrderLayout :outboundOrder="outboundOrder">
    <div class="w-100">
      <div v-if="!outboundLoading && outboundOrder" class="px-4">
        <div class="w-100">
          <div class="w-auto px-2">
            <OrderHeader
              orderType="outbound"
              :orderId="outboundOrder.id"
              :status="outboundOrder.order_status.short_label"
            />
          </div>

          <v-card class="mt-2 pa-2 w-auto" color="transparent" elevation="0">
            <!-- This section is hidden in connect c3  -->
            <div style="display: none;">
              <v-card class="mb-4" v-if="addShipmentOpen">
                <v-card-title> Add Shipment </v-card-title>
                <v-card-text>
                  <v-row >
                    <v-col cols="3">
                      <v-text-field 
                        label="Tracking Number" 
                        density="compact"
                        variant="outlined"
                        hide-details
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-autocomplete 
                        label="Select Carrier"
                        :items="carriers"
                        item-title="name"
                        item-value="id"
                        density="compact"
                        variant="outlined"
                        hide-details
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3">
                      <v-textarea 
                      label="Notes"
                      density="compact"
                      variant="outlined"
                      hide-details
                      rows="1"
                    />
                    </v-col>
                    <v-col cols="3" class="d-flex align-center ga-3">
                      <v-btn color="primary" variant="outlined" size="small" class="btn btn-primary">Add</v-btn>
                      <v-btn color="error" variant="outlined" size="small" class="btn btn-outline-danger" @click="addShipmentOpen = false">Cancel</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row class="mb-2" v-if="!addPackageOpen && !addShipmentOpen">
                <v-col cols="3">
                  <v-btn color="primary" @click="addShipmentOpen = true" v-if="!addPackageOpen">Add Shipment</v-btn>
                </v-col>
              </v-row>
            </div>
            <v-alert color="secondary" class="mb-4" v-if="shipments.total_records == 0">
              No shipments for this order.
            </v-alert>

            <v-card v-for="shipment in shipments.data" :key="shipment.id">
              <v-card-text>
                <div class="mb-0 text-subtitle-2">
                  Shipment #{{ shipment.id }}<br />
                  <span v-if="shipment.carrier?.name">
                    {{ shipment.carrier?.name }}<br />
                  </span>
            
                  {{ shipment.total_weight }} LBS ({{ shipment.total_packages }} packages)<br />
            
                  Master Tracking #: {{ shipment.tracking_number }}<br />
            
                  Created: {{ new Date(shipment.created_at).toLocaleString() }} <br />
                  Created By: {{ shipment.created_by?.name }} <br />
                  <v-btn text color="primary" size="small"
                    v-if="!shipment.tracking_number"
                        @click="showAddPackageFor(shipment.id)"
                    >Add Package</v-btn>
                </div>
              </v-card-text>
              <v-table class="mb-0">
                <thead class="bg-surface-light">
                  <tr>
                    <th>#</th>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Tracking #</th>
                    <th>Weight (lbs)</th>
                    <th>LxWxH (in)</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(pck, index) in shipment.packages">
                    <td>{{ index + 1 }}</td>
                    <td>SP-{{ pck.id }}</td>
                    <td>{{ new Date(pck.created_at).toLocaleString() }}</td>
                    <td>{{pck.tracking_number}} &nbsp;
                      <v-chip size="x-small" color="secondary" variant="elevated" elevation="0" class="badge badge-info" v-if="pck.tracking_number && pck.tracking_number == shipment.tracking_number">Master</v-chip>
                    </td>
                    <td>{{ pck.total_weight || 'Not available' }}</td>
                    <td>
                      <span v-if="pck.length && pck.width && pck.height">
                        {{ pck.length }}x{{ pck.width }}x{{ pck.height }}
                      </span>
                      <span v-else>
                        <small>Not Available</small>
                      </span>
                    </td>
                    <td>
                      {{ pck.status.name }}
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <v-row class="pa-3" v-if="addPackageOpen == shipment.id">
                <v-col cols="4">
                  <h5>Add a new Package/Pallet</h5>
                  <v-table>
                    <tbody>
                      <tr>
                        <th>Length (in.)</th>
                        <td>
                          <v-text-field 
                            type="number"
                            density="compact" 
                            variant="outlined"
                            hide-details
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Width (in.)</th>
                        <td>
                          <v-text-field 
                            type="number"
                            density="compact" 
                            variant="outlined"
                            hide-details
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Height (in.)</th>
                        <td>
                          <v-text-field 
                            type="number"
                            density="compact" 
                            variant="outlined"
                            hide-details
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Weight (lbs)</th>
                        <td>
                          <v-text-field 
                            type="number"
                            density="compact" 
                            variant="outlined"
                            hide-details
                          />
                        </td>
                      </tr>
                      <tr>
                        <th></th>
                        <td>
                          <v-btn color="primary" size="small" variant="outlined" class="mr-2">
                            Add
                          </v-btn>
                          <v-btn color="error" size="small" variant="outlined" @click="addPackageOpen = false">
                            Cancel
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </v-table>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </div>
      </div>
    </div>
  </OutboundOrderLayout>
</template>
